<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Importaciones</h4>
            <div class="small text-muted">Muestra todas las importaciones realizadas</div>
          </b-col>        
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">     
        <b-row v-if="table.mostrarFiltros">          
          <b-col sm="3">
            <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>  

          <b-col sm="3">
            <b-button variant="outline-dark" @click="filterImport()">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">     
        <b-row class="mt-3">
          <b-col lg="6">
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"                          
                    v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(date)="data">
                  {{ moment(data.item.created_at).format('DD MMMM YYYY') }}
                </template>

                <template v-slot:cell(supplier)="data">
                  {{ data.item.supplier.name }}
                </template>

                <template v-slot:cell(count)="data">
                  <div v-if="parseFloat(data.item.count_rows)>0">
                    <div v-if="parseFloat(data.item.count_rows)==1">
                      <i><b>{{ data.item.count_rows }}</b> Registro</i>
                    </div>
                    <div v-else>  
                      <i><b>{{ data.item.count_rows }}</b> Registros</i>
                    </div>
                  </div>
                  <div v-else>
                    <i>Sin Registros</i>                    
                  </div>
                </template>                

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">  
                    <b-dropdown-header>Acciones</b-dropdown-header>                    
                    
                    <b-dropdown-item @click="download(data.item)">
                      <b-icon icon="download"></b-icon> Descargar
                    </b-dropdown-item>                   
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
          <b-col lg="6">
            <b-card bg-variant="light" header="Ayuda" text-variant="dark">
              <b-card-text>
                Mostramos todas las importaciones realizada por cada proveedor.                
                <br>
                Desde la opción <i><b>Descargar</b></i>, el sistema genera nuevamente el archivo <i><b>CSV</b></i> con los datos importados.
              </b-card-text>
            </b-card>            
          </b-col>
        </b-row>  
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterImport()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Funciones from '@/handler/funciones' 

  export default {   
    data: () => {
      return {      
        access: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudImportLog',
        }, 
        table : {
          items: [],
          fields: [       
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'supplier', label: 'Proveedor', class: "align-middle"},
            {key: 'count', label: 'Registros', class: "align-middle"},            
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                 
        },       
        arr: {
          filters : {
            suppliers: [],            
          }, 
          export: [],
        },
        filters: {
          suppliers: null,
        },  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted() {  
      this.filterLoad()
      this.filterImport()   
    },
    methods: {
      // FILTER
      filterLoad() {   
        this.filterLoadSuppliers()          
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedoresOnlyImport()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {   
            if(element.active && !element.shipping) {
              this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
            }
          });          
        })   
      },    

      // DATA
      filterImport() {      
        this.table.isBusy = true
           
        var result = serviceAPI.obtenerImportListCost(this.filters, this.table.currentPage)        
        result.then((response) => {        
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data   
     
          this.table.isBusy = false           
        })
        .catch(error => {            
          this.table.isBusy = false                    
          this.$awn.alert(Error.showError(error))
        });   
      },
      download(item) {    
        this.$bvModal.msgBoxConfirm('¿Descargar lista de precios?', {
          title: 'Descargar',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'DESCARGAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {          
            this.$awn.info("Estamos procesando el archivo, por favor espere...");
            let loader = this.$loading.show();
            
            this.arr.export = []

            var result = serviceAPI.obtenerImportDetailListCost({
              import_id: item.id,
            })           
            result.then((response) => {              
              var arrData = response.data
              
              if(arrData.length) {     
                arrData.forEach(element => {              
                  var item = JSON.parse(element.content)

                  this.arr.export.push(item)                      
                });      
                
                loader.hide()
                this.$awn.success("Finalizamos el proceso, descargando archivo...");
                Funciones.exportToCSV(this.arr.export, 'list-cost-products')                    
              } else {
                loader.hide()
                this.$awn.success("Finalizamos el proceso. No se encontraron registros");
              }
            })
            .catch(error => {                 
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })          
          }
        })        
        .catch(error => {                              
          this.$awn.alert(Error.showError(error))
        });   
      }
    }    
  }
</script>
